import axios from '@axios'

export default {
  namespaced: true,
  state: {
    sensors: [],
  },
  getters: {
    getSensorSelect(state) {
      const sensorList = state.sensors
      return sensorList.map((obj => {
        const rObj = {}
        rObj.label = obj.name
        rObj.value = obj._id
        return rObj
      }))
    },
    getSensorIds(state) {
      return state.sensors.map(el => el._id)
    },
  },
  mutations: {
    SET_SENSOR(state, sensors) {
      state.sensors = sensors
    },
    CLEAR_SENSOR(state) {
      state.sensors = null
    },
  },
  actions: {
    async fetchSensors(ctx, queryParams) {
      return new Promise(async resolve => {
        let result = await axios.get('/sensor', { params: queryParams }) // collect registered sensors

        /* for(let el of result.data) {
          el.values = []
          let sensor = await ctx.dispatch('fetchSensor', { id: el._id })
          el.values = sensor.data.values 
        } */
        ctx.commit('SET_SENSOR', result.data)
        resolve(result)
      })
      
      return result
    },
    async fetchSensor(ctx, { id }) {
      const result = await axios.get(`/sensor/${id}`)
      return result
    },
    async liveFetchSensor(ctx, {id}) {
      const result = await axios.get(`/sensor/${id}/live`)
      return result
    },
    async createSensor(ctx, { queryBody }) {
      const result = await axios.post('/sensor', queryBody)
      return result
    },
    async updateSensor(ctx, { id, queryBody }) {
      const result = await axios.put(`/sensor/${id}`, queryBody)
      return result
    },
    async deleteSensor(ctx, { id }) {
      const result = await axios.delete(`/sensor/${id}`)
      return result
    },
  },
}