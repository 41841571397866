import axios from '@axios'

export default {
  namespaced: true,
  state: {
    boards: [],
  },
  getters: {},
  mutations: {
    SET_BOARD(state, board) {
      state.board = board
    },
  },
  actions: {
    /* async fetchPush({ commit }, { id }) {
      if(id === null || id === undefined) {
        const result = await axios.get(`/push/all`)
        return result
      }
      const result = await axios.get(`/push/${id}`)
      commit('SET_PUSH', result.data)
      return result
    }, */
    async fetchBoard(ctx, {}) {
      const result = await axios.get(`/board`)
      return result
    },
    async createBoard(ctx, { body }) {
      const result = await axios.post(`/board`, body)
      return result
    },
    async modifyBoard(ctx, { id, body }) {
      const result = await axios.put(`/board/${id}`, body)
      return result
    },
    async deleteBoard(ctx, { id }) {
      const result = await axios.delete(`/board/${id}`)
      return result
    },
    async getViewList(ctx, { id }) {
      const result = await axios.get(`/board/view/${id}`)
      return result
    },
    async setViewList(ctx, { id, body }) {
      const result = await axios.post(`/board/view/${id}`, body)
      return result
    },
  },
}
