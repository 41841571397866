// eslint-disable-next-line import/prefer-default-export
export const firebaseConfig = {
  apiKey: "AIzaSyBr_-1YJXOlT1z_pJqhw3ScNyvhhMiv5jA",
  authDomain: "e-farm-d3e75.firebaseapp.com",
  projectId: "e-farm-d3e75",
  storageBucket: "e-farm-d3e75.appspot.com",
  messagingSenderId: "636244135307",
  appId: "1:636244135307:web:eede110a32398ba747738e",
  measurementId: "G-E7DHW8G2TF"
}
