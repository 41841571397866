export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/users/UserDashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/cctv',
    name: 'cctv',
    component: () => import('@/views/users/cctv-viewer/CCTVViewer.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('@/views/users/monitoring/SensorMonitor.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/network',
    name: 'network',
    component: () => import('@/views/users/monitoring/NetworkStateTable.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/remote/control',
    name: 'remote-control',
    component: () => import('@/views/users/UserRemoteControl.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/remote/setting',
    name: 'remote-setting',
    component: () => import('@/views/users/remote-setting/AutomationSetting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/remote/setting',
    name: 'remote-setting1',
    component: () => import('@/views/users/remote-setting/RemoteClean.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/remote/setting/test',
    name: 'remote-setting-test',
    component: () => import('@/views/users/remote-setting/AutomationSetting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/remote/setting',
    name: 'remote-edit',
    component: () => import('@/views/users/remote-setting/RemoteEdit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/stream',
    name: 'watch-cctv',
    component: () => import('@/views/pages/auth/userCctv.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/feederLog',
    name: 'feeder-log',
    component: () => import('@/views/users/monitoring/NetworkStateTable.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  
  {
    path: '/board/manual',
    name: 'manual',
    component: () => import('@/views/users/board/Manual.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },

]
