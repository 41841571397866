<template>
	<transition name="modal" v-if="showModal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header" style="justify-content: center;">
						<h1 name="header" style="color: black">
							<p clss="modal-header-h"><b>공지사항</b></p>
						</h1>
					</div>

					<div class="modal-body" style="padding-bottom: 1px;">
						<h2 name="body">
							<pre
								class="modal-body-p"
								style="background-color:white; -webkit-padding-start:0px; margin-bottom:4px;"
							> 
<div style="text-align:center; padding-bottom:1em;"> 안녕하세요. 이화글로벌입니다.
서버 안정화 및 기능 업데이트를
위한 점검이 진행될 예정입니다.

※점검 일시※
<div style="color:red;">
{{year}}년 {{month}}월 {{day}}일({{week}}) 
{{timeA}} ~ {{timeB}} ({{timeC}}시간) 
</div>
▶점검 내용◀

{{contents}} 

▶주의 사항◀

점검이 진행되는 동안 작동이 
되지 않을 수 있습니다.


상황에 따라 점검 내용이
변경되거나 조기 종료
 될 수 있습니다. 
더욱 안정적인 서비스 제공을 위해 
노력하겠습니다.

감사합니다.

{{nowtime}}</div> 
</pre>
						</h2>
					</div>
					<div class="modal-footer">
						<button class="modal-default-button2" @click="setCookie" style="margin-right:10px">
							오늘 하루 그만보기
						</button>
						<button class="modal-default-button" @click="$emit('close')">
							창 닫기
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			showModal: true,
			year: "2022",
			month: "06",
			day: "10",
			week: "수",
			timeA: "10:00",
			timeB: "18:40",
			timeC: "1",
			contents: "자동화 기능 추가 API 코드 개선"
		};
	},

	mounted() {
		const today = new Date();
		const end = new Date(this.year + "-" + this.month + "-" + this.day + " " + this.timeB);
		const expiretime = (end.getTime() - today.getTime()) / 1000;

		document.cookie = "ewha=end; max-age=" + Math.round(expiretime) + ";";

		if (document.cookie !== "notice=today" && document.cookie === "ewha=end") {
			this.showModal = true;
		} else if (document.cookie !== "notice=today" && document.cookie !== "ewha=end") {
			this.showModal = false;
		} else if (document.cookie === "notice=today" && documnet.cookie === "ewha=end") {
			this.showModal = false;
		} else if (document.cookie === "notice=today" && document.cookie !== "ewha=end") {
			this.showModal = false;
		}
	},

	methods: {
		setCookie: function() {
			const date = new Date();
			const todayexpiretime = (24 - date.getHours()) * 3600;

			document.cookie = "notice=today; max-age=" + todayexpiretime + ";";
			return (this.showModal = false);
		}
	},
	computed: {
		nowtime() {
			const date2 = new Date();
			let week = new Array("일", "월", "화", "수", "목", "금", "토");
			let year2 = date2.getFullYear();
			let month2 = date2.getMonth() + 1;
			let day2 = date2.getDate();
			let dayName = week[date2.getDay()];

			return year2 + "년 " + month2 + "월 " + day2 + "일 " + dayName + "요일 ";
		}
	}
};
</script>

<style>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.5em;
}

.modal-container {
	width: 600px;
	border-radius: 10px;
	overflow: hidden;
	background-color: #49ac6a;
	box-shadow: 5px 10px 10px 1px rgba(0, 0, 0, 0.3);
	height: 95%;
    margin-bottom:2em;
}

.modal-body{
    height: 85%;
}

.modal-header {
	width: 50%;
	margin: auto;
	background-color: white;
	height: 50px;
	border-radius: 10px;
}

.modal-body-p {
	top: 10px;
	background-color: #fff;
	font-weight: bold;
	width: 100%;
	border-radius: 10px;
	font-size: x-large;
	height: 28em;
}

.modal-footer {
	margin-right: 15px;
}

.modal-default-button2 {
	padding: 1px;
	width: 180px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #ffffff;
	font-weight: bold;
	font-size: medium;
}

.modal-default-button2:hover {
	color: white;
	background-color: #42b983;
}

.modal-default-button {
	padding: 1px;
	width: 80px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #ffffff;
	border-radius: 5px;
	color: #ffffff;
	font-weight: bold;
	font-size: medium;
}

.modal-default-button:hover {
	color: white;
	background-color: #42b983;
}
.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.modal-body,
.modal {
	color: #666 !important;
}
pre {
	white-space: pre-wrap;
}
</style>
