import axios from '@axios'

export default {
  namespaced: true,
  state: {
    buttons: [],
  },
  getters: {
    getButtonCheckbox(state) {
      const buttonList = state.buttons
      return buttonList.map((obj => {
        const rObj = {}
        rObj.text = obj.name
        rObj.value = obj._id
        return rObj
      }))
    },
    getAllButtonForRelay: state => farmId => {
      let { buttons } = state

      if (farmId !== '') buttons = buttons.filter((obj => obj.farmId._id === farmId))

      return buttons.map((obj => {
        const rObj = {}
        rObj.label = obj.name
        rObj.value = obj._id
        rObj.relayCnt = obj.buttonSetting.relayCount
        return rObj
      }))
    },
    getButtonForRelay: state => buttonId => {
      const { buttons } = state

      if (buttonId === null) {
        return null
      }

      const button = buttons.filter((obj => obj._id === buttonId))

      return button.map((obj => {
        const rObj = {}
        rObj.label = obj.name
        rObj.value = obj._id
        rObj.relayCnt = obj.buttonSetting.relayCount
        return rObj
      }))[0]
    },
    getButtonTypes: state => farmId => {
      let { buttons } = state
      
      if (farmId !== '') buttons = buttons.filter((obj => obj.farmId._id === farmId))
      return buttons
        .map((obj => obj.type))
        .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
        .filter((obj => obj !== 'fan_setting'))
        // FEEDER EXCEPT
        /* .filter((obj => obj !== 'feeder_1button')) */
        .filter((obj => obj !== 'feeder_necklace'))
        .filter((obj => obj !== 'hydraulic_complex'))
        .filter((obj => obj !== 'hydraulic_trigger'))
        .filter((obj => obj !== 'hydraulic_trigger2'))
        .filter((obj => obj !== 'hydraulic_1trigger'))
        //.filter((obj => obj !== 'curtain_toggle'))
        /* .filter((obj => obj !== 'ceiling_2button'))
        .filter((obj => obj !== 'feeder_1button')) */
        .map((obj => {
          const rObj = {}
          rObj.value = obj
          if (obj === 'curtain_toggle' || obj === 'curtain') rObj.label = '커튼'
          else if(obj === 'curtain_sj')  rObj.label = '커튼제어'
          else if (obj === 'ceiling' || obj === 'ceiling_reverse' || obj === 'ceiling_2button') rObj.label = '천장'
          else if (obj === 'feeder' || obj === 'feeder_1button') rObj.label = '사료'
          else if (obj === 'farm_1') rObj.label = '통합 제어'
          else if (obj === 'light') rObj.label = '조명' //
          else if (obj === 'cooler_sh') rObj.label = '냉각기' 
          else if (obj === 'plc_fan_jnk') rObj.label = '제어기' 
          else if (obj === 'fan' || obj === 'fan_trigger' || obj === 'fan_control' || obj === 'fan_control_sj' || obj === 'inverter_control_sj') rObj.label = '선풍기'
          else if (obj === 'car_disinfector_toggle' || obj === 'car_disinfector_trigger') rObj.label = '차량소독'
          else if (obj === 'sprayer' || obj === 'sprayer_trigger') rObj.label = '안개분무'
          else if (obj === 'inverter' || obj === 'inverter_hd' || obj === 'inverter_hz') rObj.label = '인버터'
          return rObj
        }))
    },
    getButtonInType: state => type => state.buttons.filter((obj => obj.type === type)),
    getButtonInTypeOptions: state => type => state.buttons
      .filter((obj => obj.type === type))
      .map((obj => {
        const rObj = {}
        rObj.text = `(${obj.farmId.name}) ${obj.name}`
        rObj.value = obj._id
        return rObj
      })),
  },
  mutations: {
    SET_BUTTONS(state, buttons) {
      state.buttons = buttons
    },
  },
  actions: {
    async fetchButtons({ commit }, queryParams) {
      const result = await axios.get('/button', { params: queryParams })
      commit('SET_BUTTONS', result.data)
      return result
    },
    async fetchButton(ctx, { id }) {
      const result = await axios.get(`/button/${id}`)
      return result
    },
    async createButton(ctx, { queryBody }) {
      const result = await axios.post('/button', queryBody)
      return result
    },
    async updateButton(ctx, { id, queryBody }) {
      const result = await axios.put(`/button/${id}`, queryBody)
      return result
    },
    async deleteButton(ctx, { id }) {
      const result = await axios.delete(`/button/${id}`)
      return result
    },
    async updateButtonMany(ctx, { queryBody }) {
      const result = await axios.put('/button', queryBody)
      return result
    },

    async addSettingMany(ctx, { queryBody }) {
      const result = await axios.post('/button/settings', queryBody)
      return result
    },

    async addNewSetting(ctx, { id, queryBody }) {
      const result = await axios.post(`/button/${id}/setting`, queryBody)
      return result
    },
    async setSettings(ctx, { id, queryBody }) {
      const result = await axios.post(`/button/${id}/settings`, queryBody)
      return result
    },

    async fetchSettings(ctx, { id }) {
      const result = await axios.get(`/button/${id}/settings`)
      return result
    },
    async deleteSetting(ctx, { buttonId, settingId }) {
      const result = await axios.delete(`/button/${buttonId}/setting/${settingId}`)
      return result
    },

    async command(ctx, { id, queryBody }) {
      const result = await axios.post(`/button/${id}/command`, queryBody, {'timeout': 5000})
      return result
    },

    async getState(ctx, { id }) {
      const result = await axios.get(`/button/state/${id}`)
      return result
    },
    async getStateByCommand(ctx, { id, command }) {
      const result = await axios.get(`/button/state/${id}/${command}`)
      return result
    },

    async updateSetting(ctx, {id, queryBody}) {
      const result = await axios.put(`/button/${id}/setting`, queryBody)
      return result
    },
    async getButtonConnect(ctx, { id }) {
      const result = await axios.get(`button/${id}/connect`)
      return result
    },
    async getFeedList(ctx, { buttonId }) {
      const result = await axios.get(`button/feed/${buttonId}`)
      return result
    },
    async getBtnLogList(ctx, { buttonId }) {
      const result = await axios.get(`button/log/${buttonId}`)
      return result
    },
    async getBtnLogListByDate(ctx, { buttonId, date }) {
      const result = await axios.get(`button/log/${buttonId}/${date}`)
      return result
    },
    async updateValueOfLog(ctx, { logIdx, queryBody }) {
      const result = await axios.put(`button/log/${logIdx}`, queryBody)
      return result
    }
  },
}
