import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://app.e-farm.site/api/',
  timeout: 3500,
  headers: { 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
