export default [
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/admin/user-list/UsersList.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '사용자 목록',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/view/:id',
    name: 'user-view',
    component: () => import('@/views/admin/user-view/UserView.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '사용자 목록',
          to: '/user/list',
          active: false,
        },
        {
          text: '사용자 정보',
          active: true,
        },
      ],
    },
  },
  /* {
    path: '/board',
    name: 'board-info',
    component: () => import('@/views/users/board/info.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '공지사항',
          active: true,
        },
      ],
    },
  }, */
  {
    path: '/network',
    name: 'network-status',
    component: () => import('@/views/admin/network/NetworkStatusTable.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '장치 현황',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cctv',
    name: 'cctv-admin',
    component: () => import('@/views/admin/network/cctv.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: 'CCTV',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data',
    name: 'make-data-sample',
    component: () => import('@/views/admin/network/data.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '정보 연계',
          active: true,
        },
      ],
    },
  },//make-data-sample
  /* {
    path: '/push-write',
    name: 'push-write',
    component: () => import('@/views/pages/auth/test.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '글 작성',
          active: true,
        },
      ],
    },
  }, */
  {
    path: '/push-write',
    name: 'push-write',
    component: () => import('@/views/pages/auth/Write.vue'),
    meta: {
      resource: 'Admin',
      action: 'manage',
      breadcrumb: [
        {
          text: '메시지 전송',
          active: true,
        },
      ],
    },
  },
]
